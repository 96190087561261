@import "variables";
@import "fonts";
@import "normalize";
@import "btn";

@import "footer";
@import "logo_mc";



body {
	color: #fff;
	background: #0f1c2c;
	font-family: Arial, Helvetica, sans-serif;
	overflow-x: hidden;

}

//wrapper
#wrapper {
	padding: 0 1em;
}

header {
	padding: 0 0 0 50px;

	.logo_mc {
		margin: 20px 0 0 -50px;
		font-size: 2em;
		color: #fff;
	}

	.btn {
		float: right;
		margin: 1.5em 0 2em;
	}
}

//main
main {
	max-width: 900px;
	margin: 0 auto;
	padding: 6em 0 4em;
	line-height: 1.4em;

	p {
		color: var(--grey-medium);
	}

	a {
		color: #fff;
	}

	.img_large {
		width: calc(100% + 2em);
		margin: 1em -1em;
		height: auto;
		box-sizing: border-box;
		max-width: none;
	}

	.prefooter {
		margin: 4em 0 -1em;
	}
}

h1 {
	font-family: 'Galada', cursive;
	line-height: 1em;
	font-weight: 400;
	margin: 0;

	.tag {
		display: inline-block;
		background: #e6513a;
		padding: 0.25em 0.5em 0em;
		vertical-align: middle;
		font-size: .75em;

		border-radius: .25em;
		margin: 0 .5em;
	}
}

h1 strong {
	font-weight: 400;
	color: #f2994a;
	position: relative;

	&:after {
		content: "";
		background-image: url("img/etoiles.svg");
		background-size: 1em auto;
		width: 1em;
		height: 0.72em;
		position: relative;
		display: block;
		position: absolute;
		right: -.22em;
		top: -.44em;
	}
}

//fomulaire d'invit
#analyse-launched {
	position: absolute;
	opacity: 0;
	height: 1px;
	width: 1px;
}

.invitation {
	div {
		font-size: 14px;

		line-height: 1.4em;
		margin: 1.5em 0 2em;
		position: relative;

	}

	div:before {
		--ld_size: 1em;
		--ld_border: 4px;
		content: " ";
		display: block;
		width: var(--ld_size);
		height: var(--ld_size);
		border-radius: 50%;
		border: var(--ld_border) solid #fff;
		border-color: #fff transparent #fff transparent;
		position: absolute;
		right: 1em;
		bottom: 0.85em;
		z-index: -1;
		opacity: 0;
		--lz: 3;
	}

	div:after {
		position: absolute;
		z-index: -1;
		--lz: 2;
		content: "Lancement de la procèdure.";
		color: #fff;
		width: 100%;
		font-size: 14px;
		display: block;
		background: #1d3243 !important;
		border-radius: 8px;
		border: none;
		padding: 1em 1.5em;
		box-sizing: border-box;
		margin: 0;
		opacity: 0;
		bottom: 0;

	}

	input:invalid+.btn {
		pointer-events: none;
	}


}

.full-width {
	background: rgba(0, 0, 0, 0.25);
	padding: 2em 0;
	max-width: 100%;
	overflow-x: auto;
	font-size: 0.8em;
	--innerpadding: 0.75em
}

.classement-table {

	margin: 0 auto;
	--bg1: #00aec2;
	--bg2: #ff9100;

	.prct_1p {
		color: var(--bg1);
		margin: 0;
		float: left;
		clear: left;
		font-size: 0.9em;
	}

	.prct_3p {
		color: var(--bg2);
		margin: 0;
		float: right;
		clear: right;
		font-size: 0.9em;
	}

	.bar {
		border-radius: 4px;
		display: block;
		vertical-align: middle;
		margin: 0;
		height: 1.64em;
		line-height: 1.64em;
		font-size: 5px;
		color: #000;
		text-indent: 3px;
		box-sizing: border-box;
		white-space: nowrap;

		clear: both;
	}

	.bar {
		--bar_width: 200px;

		--sector1: calc(var(--value1p) / 100 * var(--bar_width));
		--sector2: calc(100 / 100 * var(--bar_width));
		width: var(--bar_width);
		background-image: linear-gradient(90deg, var(--bg1) var(--sector1), var(--bg2) var(--sector1), var(--bg2) 100%);
	}

	td,
	th {
		text-align: left;

		&.text-center {
			text-align: center;
		}
	}

	th {
		font-size: 1.2em;
		padding: 1em var(--innerpadding);

	}

	td {
		font-size: 1.1em;
		padding: 0.5em var(--innerpadding) 0.5em 0;
	}

	td {
		font-weight: bold;
	}

	tr {
		&:hover>td {
			cursor: pointer;
			background: rgba(255, 255, 255, 0.05);
		}

		td,
		th {
			border-left: 1px solid #333;
			padding-left: var(--innerpadding);
			white-space: nowrap;
		}

		&>:first-child {
			border-left: none;

		}

		&>:last-child {
			padding-right: 0;
			width: 200px;
		}
	}



	.position {
		display: inline-block;
		line-height: 2.2em;
		height: 2.2em;
		width: 2.2em;
		border: 1px solid #ddd;
		border-radius: 50%;
		vertical-align: middle;
		background: rgba(255, 255, 255, 0.05);
	}

	.good {
		color: var(--color_good);

	}

	.bof {
		color: var(--color_bof);
	}

	.bad {
		color: var(--color_bad);
	}
}

.invitation,
.contact {

	.btn {
		font-size: 14px;
		line-height: 14px;
		border: 2px solid #1d3243;
		padding: 11px 1.5em;
		float: right;
		margin: -3.08em 2px 0 0;
		background: #db525c;
		color: #fff;

		&[disabled] {
			pointer-events: none;
			opacity: 0.75;
		}
	}



	.input-field {
		position: absolute;
		z-index: -1;
		top: -100000px;
	}

	.error {
		color: var(--color_verybad);
	}

	.success {
		font-size: 1.25em;
		color: var(--color_good);
	}

	.h-captcha {
		margin-top: 2em;
	}


}

.invitation {

	#url,
	#address {
		border: 1px solid #0f1c2c;

		&:invalid:not(:placeholder-shown) {
			border-color: red;
		}
	}

	button {
		position: relative;
		z-index: 3;
		opacity: 0;
		transition: all 0.15s linear 3.9s;
	}

	#analyse-launched:checked+div:after {
		animation: waiting 4s backwards linear;
	}

	#analyse-launched:checked+div:before {
		animation: loader 1s 2 linear;
	}

	#analyse-launched:checked+div #url,
	#analyse-launched:checked+div label {
		display: none;
	}


	#address,
	#address~.h-captcha {
		display: none !important;
	}

	#analyse-launched:checked+div #address,
	#analyse-launched:checked+div .h-captcha {
		display: block !important;
	}

	#analyse-launched:checked+div button {
		opacity: 1;

	}
}

@keyframes loader {
	0% {
		z-index: var(--lz);
		opacity: 1;
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
		z-index: var(--lz);
		opacity: 1;
	}
}

@keyframes waiting {
	0% {
		z-index: var(--lz);
		opacity: 0;
	}

	1% {

		opacity: 1;
	}

	100% {
		z-index: var(--lz);
		opacity: 1;
		content: "L'analyse va être effectuée !"
	}
}

#url,
#address,
.form-control {
	color: #fff;
	width: 100%;
	font-size: 14px;
	display: block;
	background: #1d3243 !important;
	border-radius: 8px;
	border: none;
	padding: 1em 1.5em;
	box-sizing: border-box;
	font-family: inherit;
}

//footer
footer {
	align-self: end;
}

.text-center {
	text-align: center;
}



@media (max-width: 1023px) {
	#wrapper.big_shaker {
		main {
			padding: 8px;
			background-image: url(img/mc_txt_digit_trans.webp);
			background-repeat: no-repeat;
			box-sizing: content-box;
			background-size: 400px auto;
			background-position: center bottom;
			min-height: 730px;

		}

	}

	h1 {
		margin: 1em 0 0.5em;
		font-size: 1.75em;
	}

	.invitation p br {
		display: none;
	}

	.contact .btn,
	.invitation .btn {
		margin-top: 1em;
	}

	ul {
		line-height: 2em;
	}

}

@media (min-width:1024px) {
	#wrapper {
		padding: 0 2em;
	}

	main {
		.img_large {
			width: calc(100% + 8em);
			margin: 2em -4em;
			border-radius: 26px;
			border: 8px solid #444b53;
			box-sizing: border-box;

		}
	}

	#wrapper.big_shaker {
		display: grid;
		grid-template-rows: 5% auto 5%;
		margin: 0 auto;
		height: 95vh;

		background-size: min(54%, 100vh);
		background-image: url("img/mc_txt_digit_trans.webp");
		background-repeat: no-repeat;
		box-sizing: content-box;
		background-position: 40vw center;

		main {
			background: none;
			max-width: 28em;
			align-self: center;
			padding: 0 0 0 calc(40vw - 28em);
			margin: 0;
		}

		nav {
			padding-bottom: 0;
		}

	}

	h1 {
		font-size: 2.8em;
	}

	.full-width {
		--innerpadding: 2em;
		font-size: 1em;
		max-width: none;

		width: 100vw;
		margin: 4em calc((100vw - 900px) / 2 * -1);
	}

	.classement-table {
		min-width: 1024px;

		th[title]:after {
			content: "i";
			display: inline-block;
			line-height: 19px;
			height: 19px;
			width: 19px;
			vertical-align: top;
			font-size: 14px;
			text-align: center;
			margin-left: 8px;
			border-radius: 50%;
			background: hsla(0, 0%, 100%, .3);
			color: #0a0a0a;
		}
	}
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border-width: 0;
}